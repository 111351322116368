////////////////////////////////////////////
///////// BLOCK 4
//////////////////////////////////////////////

@-webkit-keyframes svg-text-anim {
  40% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 0;
    fill: #ffffff;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffffff;
  }

}

.g ,.g2,.g3{
stroke:#000;
  stroke-width:0.25mm;
  fill:none;
}

/* Most browsers */
@keyframes svg-text-anim {
  40% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 0;
    fill: #ffffff;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffffff;
  }

}




.block4 {
  margin-top: 70px;
  background: url("../img/bgImages/block3.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 930px;
  padding: 50px;
}

.block4__container {
  max-width: 1500px;
  margin: 0 auto;
}

.block4__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 88px;
  text-align: left;
  text-transform: uppercase;
  width: max-content;
  color: #FFFFFF;
  border-bottom: 9px solid #FFFFFF;
}

.block4__subtitle__container {
  margin-top: 50px;
}

.block4__subtitle {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  color: #FFFFFF;
}

.block4__title__middle__container {
  margin-top: 150px;
}

.block4__title__middle {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}


.block4__list__container {
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.block4__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block4__list__bulk{
  margin-top: auto;
  display: flex;
  justify-content: center;
}
.block4__list__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  text-transform: lowercase;
  color: #FFFFFF;

}

.block4__list__text__big {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 88px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  color: #FFFFFF;
}


@media screen and (max-width: 1300px) {
  .block4{
    min-height: 775px;
  }
  .block4__title {
    font-size: 54px;
    line-height: 68px;
    border-bottom: 8px solid #FFFFFF;
  }
  .block4__subtitle {
    font-size: 26px;
    line-height: 34px;
  }
  .block4__title__middle {
    font-size: 28px;
    line-height: 40px;
  }
  .block4__list__text {
    font-size: 26px;
    line-height: 34px;
  }
  .block4__list__text__big {
    font-size: 54px;
    line-height: 68px;
  }
}
@media screen and (max-width: 900px) {
  .block4__title {
    font-size: 44px;
    line-height: 58px;
    border-bottom: 7px solid #FFFFFF;
  }
  .block4__subtitle {
    font-size: 24px;
    line-height:29px;
  }
  .block4__title__middle {
    font-size: 26px;
    line-height: 36px;
  }
  .block4__list__text {
    font-size: 24px;
    line-height: 30px;
  }
  .block4__list__text__big {
    font-size: 44px;
    line-height:58px;
  }
}
@media screen and (max-width: 800px) {
  .block4__title {
    font-size: 24px;
    line-height: 33px;
    border-bottom: 4px solid #FFFFFF;
  }
  .block4__subtitle {
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .block4__title__middle__container {
    margin-top: 120px;
  }
  .block4__title__middle {
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .block4__list__container{
    flex-direction: column;
    margin-top: 80px;
  }
  .block4__list,.block4__list__bulk{
    margin-top: 40px;
  }
  .block4__list__text {
    font-size: 15px;
    line-height: 21px;
  }
  .block4__list__text__big {
    font-size: 20px;
    line-height: 28px;
    margin-top: 0px;
  }
  .block4__list__text__bulk{
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .block4__subtitle {
    width: 100%;
  }
  .block4__title__middle {
    width: 100%;
  }
  .block4__list__container {
    margin-top: 0;
  }
}
@media screen and (max-width: 375px) {
  .block4{
    padding: 25px;
    min-height: 700px;
  }
}