.sidebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Helvetica", sans-serif;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 70px;
}
.sidebar__left__container{
  max-width: 390px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sidebar__right__container{
  max-width: 390px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.link__anchor {
  text-decoration: none;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  text-transform: lowercase;
  color: #FFFFFF;
}

.sidebar__img {
  width: 85px;
  height: 82px;
  object-fit: contain;
}

@media screen and (max-width: 1300px) {
  .sidebar__left__container {
    margin-right: 50px;
  }
  .sidebar__right__container{
    margin-left: 50px;
  }
  .link__anchor{
    font-size: 22px;
    line-height: 30px;
  }
}
@media screen and (max-width: 900px) {
  .sidebar{
    justify-content: center;
  }
  .sidebar__left__container {
   display: none;
  }
  .sidebar__right__container{
    display: none;
  }
  .sidebar__img{
    height: 95px;
    width: 99px;
  }
}