////////////////////////////////////////////
///////// BLOCK 5
//////////////////////////////////////////////
.block5 {
  margin-top: 70px;
  background: url("../img/bgImages/block4.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 930px;
  padding: 100px 40px;
}
.block5__container{
  max-width: 1500px;
  margin: 0 auto;
}
.block5__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 128px;
  line-height: 177px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.block5__text__container{
  margin-top: 40px;
  margin-left: auto;
  width: max-content;
  padding: 10px;
}
.title__span{
  height: 13px;
  width: 400px;
  background: #FFFFFF;
  display: block;
  position: relative;
  left: 30px;
}
.block5__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 128px;
  line-height: 177px;
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width: 1440px) {
  .block5__title ,.block5__text {
    font-size: 110px;
    line-height: 152px;
  }
}

@media screen and (max-width: 1250px) {
  .block5__title ,.block5__text{
    font-size: 80px;
    line-height: 122px;
  }
  .title__span {
    left: 0px;
  }
}
@media screen and (max-width: 900px) {
  .block5{
    min-height: 700px;
  }
  .block5__container{
    margin-top: 90px;
  }
  .block5__text__container {
    width: 320px;
    float: right;
  }
  .block5__title ,.block5__text{
    font-size: 50px;
    line-height: 69px;
  }
  .title__span {
    height: 5px;
    width: 175px;
  }
}

@media screen and (max-width: 600px) {
  .block5 {
    padding: 100px 10px;
  }
}