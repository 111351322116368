////////////////////////////////////////////
///////// BLOCK 6
//////////////////////////////////////////////
.block6 {
  margin-top: 70px;
  background: url("../img/bgImages/block5.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 930px;
  padding: 140px 50px;
}

.block6__container {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 490px;
  justify-content: space-between;
}

.block6__title__container {
  float: right;
}

.block6__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 88px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 13px solid #FFFFFF;
  float: right;
  width: max-content;
}

.block6__text__container {
  margin-top: 100px;
}

.block6__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
  color: #FFFFFF;
}
.block6__text__uppercase{
  text-transform: uppercase;
}
.block6__text__margin {
  margin: 35px 0;
}

@media screen and (max-width: 1200px) {
  .block6__title{
    margin: 0 auto;
    float: none;
    font-size: 44px;
    line-height: 68px;
  }
  .block6__text__container {
    margin-top: 120px;
  }
  .block6__text{
    font-size: 24px;
    line-height: 36px;
  }
}
@media screen and (max-width: 900px) {
  .block6{
    padding: 100px 50px;
    min-height: 700px;
  }
  .block6__title{
    font-size: 20px;
    line-height: 28px;
    border-bottom: 4px solid #FFFFFF;
  }
  .block6__text__container {
    width: 70%;
  }
  .block6__text{
    font-size: 26px;
    line-height: 40px;
    font-weight: 400;
  }
}
@media screen and (max-width: 600px) {
  .block6{
    padding: 70px 25px;
  }
  .block6__title{
   float: right;
  }
  .block6__text__container {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
    .block6__text {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
}



