.footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__img{
  width: 100%;
  height: 100%;
  max-width: 87px;
  max-height: 87px;
  object-fit: contain;
}