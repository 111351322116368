////////////////////////////////////////////
///////// BLOCK 2
//////////////////////////////////////////////

.block2 {
  margin-top: 70px;
  background: url("../img/bgImages/block2.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 930px;
  padding: 50px;
  font-family: 'Helvetica', sans-serif;
}

.block2__container {
  max-width: 1500px;
  margin: 200px auto;
}

.block2__text__title__wrapper {
  display: flex;
  justify-content: space-between;

}

.block2__text__top__container {
  width: 75%;
}

.block2__text__top {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  color: #FFFFFF;
}

.block2__text__top__capital {
  text-transform: uppercase;
}

.block2__title__container {

}

.block2__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 88px;
  text-align: center;
  text-transform: capitalize;
  border-bottom: 9px solid #FFFFFF;
  color: #FFFFFF;
}

.block2__list__wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-left: 100px;
  margin-top: 50px;
}

.block2__list__container1 {

}

.block2__list__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block2__list {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 55px;
  color: #FFFFFF;

}

.block2__list__container2 {

}

.block2__list__icon__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}

.list__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list__icon__img {
  width: 100%;
  height: 100%;
  max-height: 129px;
  max-width: 133px;
  object-fit: contain;
}

.list__icon__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #FFFFFF;

}

.list__icon__text__bold {
  font-weight: bold;
}

.block2__text__bottom {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 70px;

}

@media screen and(max-width: 1550px) {
  .block2__text__bottom {
    text-align: center;
    width: 70%;
    margin: 70px auto;
  }
}

@media screen and(max-width: 1440px) {
  .block2__container {
    margin: 140px 0 0 0;
  }
  .block2__text__title__wrapper {
    flex-direction: column;
  }
  .block2__title__container {
    display: flex;
    margin-left: auto;
    order: 1;
  }
  .block2__text__top__container {
    display: flex;
    flex-direction: column;
    order: 2;
    width: 100%;
    margin-top: 30px;
  }
  .block2__list__icon__container {
    flex-wrap: wrap;
    justify-content: space-around;
    grid-gap: 50px 30px;
  }
}

@media screen and(max-width: 1350px) {
  .block2__container {
    margin: 0;
  }
  .block2__title {
    font-size: 54px;
    line-height: 74px;
  }
  .block2__text__top {
    font-size: 22px;
    line-height: 32px;
  }
  .block2__list {
    font-size: 26px;
    line-height: 45px;
  }
  .list__icon {
    max-width: 200px;
  }
  .list__icon__img {
    max-height: 100px;
    max-width: 100px;
  }

  .list__icon__text {
    font-size: 20px;
    line-height: 28px;
  }
  .block2__text__bottom {
    font-size: 30px;
    line-height: 42px;
  }
}
@media screen and(max-width: 950px) {
  .block2{
    min-height: 730px;
  }
  .block2__title{
    font-size: 24px;
    line-height: 33px;
  }
  .block2__text__top{
    font-size: 16px;
    line-height: 22px;
  }
  .block2__list__wrapper {
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: 100px;
  }
  .block2__list__icon__container {
    width: 90%;
    margin: 0 auto;
  }
  .block2__list{
    font-size: 17px;
    line-height: 23px;
  }
  .list__icon__img {
    max-height: 80px;
    max-width: 80px;
  }
  .list__icon__text {
    font-size: 15px;
    line-height: 21px;
  }
  .block2__text__bottom {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    margin: 70px 0 0 0;
  }
}
@media screen and(max-width: 650px) {
  .block2__list__icon__container {
    width: 100%;
  }
}
@media screen and(max-width: 600px) {

  .block2{
    padding:25px;
  }
  .block2__title {
    font-size: 24px;
    line-height: 33px;
    border-bottom: 4px solid #FFFFFF;
  }
  .block2__text__top__container {
    margin-top: 15px;
  }
  .block2__list__wrapper{
    width: 100%;
    margin: 30px auto;
  }
  .list__icon__img {
    max-height: 70px;
    max-width: 70px;
  }
  .block2__list__icon__container {
    grid-gap: 40px 5px;
  }
  .list__icon {
    max-width: 140px;
  }
}
@media screen and(max-width: 450px) {
  .block2 {
    padding: 10px;
  }
}
@media screen and(max-width: 415px) {
  .block2__text__top {
    font-size: 14px;
    line-height: 20px;
  }
  .list__icon__text {
    font-size: 13px;
    line-height: 18px;
  }
}