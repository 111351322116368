////////////////////////////////////////////
///////// BLOCK 1
//////////////////////////////////////////////
.main {
  font-family: Helvetica, sans-serif;
  background: #ffffff;
  overflow: hidden;
}

.block1 {
  background: url("../img/bgImages/block1.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 930px;
  padding: 50px 0;
}

.block1__container {
  max-width: 1320px;
  margin: 200px auto;
}

.block1__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 128px;
  line-height: 177px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  border-top: 25px solid #FFFFFF;
  border-bottom: 25px solid #FFFFFF;
  padding: 50px 0;
}

.block1__text__container {
  margin-top: 50px;
  width: 80%;
}

.block1__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  color: #FFFFFF;
}

@media screen and(max-width: 1400px) {
  .block1__container{
    padding: 0 80px;
    margin: 170px auto;
  }
  .block1__title{
    font-size: 80px;
    line-height: 100px;
    text-align: left;
  }
  .block1__text {
    font-size: 24px;
    line-height: 34px;
  }
}
@media screen and(max-width: 1100px) {
  .block1__container{
    padding: 0 80px;
    margin: 120px auto;
  }
  .block1__title {
    font-size: 60px;
    line-height: 80px;
  }
  .block1__text__container{
    width: 92%;
  }
  .block1__text {
    font-size: 22px;
    line-height: 32px;
  }
}
@media screen and(max-width: 900px) {
  .block1__title {
    font-size: 32px;
    line-height: 44px;
    border-top: 9px solid #FFFFFF;
    border-bottom: 9px solid #FFFFFF;
    padding: 25px 0;
  }
  .block1__text__container{
    width: 84%;
    margin: 120px auto;
  }
  .block1__text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  .block1__text__margin{
    margin-top: 30px;
  }
}
@media screen and(max-width: 600px) {
  .block1{
    min-height: 800px;
  }
  .block1__container {
    padding: 0 40px;
  }
  .block1__title {
    text-align: center;
  }
  .block1__text__container{
    width: 100%;
  }
  .block1__text__margin{
    margin-top: 15px;
  }
}
@media screen and(max-width: 410px) {
  .block1__container{
    padding: 0 20px;
  }
}
@media screen and(max-width: 370px) {
  .block1__title {
    font-size: 25px;
    line-height: 35px;
    padding: 15px 0;
  }
}