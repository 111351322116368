.block3 {
  padding: 0 10px;
}

.block3__title__container {
  margin-top: 35px;
  margin-right: 150px;
}

.block3__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 88px;
  text-align: center;
  text-transform: capitalize;
  margin-left: auto;
  width: max-content;
  color: #31373E;
  border-bottom: 9px solid #31373E;
}

.block3__list__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1600px;
  margin: 150px auto;
}

.block3__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  position: relative;
  transition: 0.5s all ease;
}

.ammoniumSulfate {
  margin-top: 85px;
}

.sulfur {
  margin-top: 85px;
}


.block3__list:hover .block3__list__img {
  opacity: 0;
  transition: 0.5s all ease;
}

.block3__list:hover .block3__list__text {
  opacity: 1;
  transition: 0.5s all ease;
}

.block3__list:hover .block3__list__title {

  bottom: 245px;
  transition: 0.5s all ease;
}

.block3__list__img {
  width: 185px;
  height: 185px;
  object-fit: contain;
  cursor: default;
  transition: 0.5s all ease;
}

.block3__list__title {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-top: 23px;
  position: relative;
  bottom: 0;
  transition: 0.5s all ease;
}

.block3__list__text {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  opacity: 0;
  position: absolute;
  top: 40px;
  cursor: default;
  transition: 0.5s all ease;
}

.block3__subtitle__bold {
  font-weight: bold;
}

.block3__text__bold {
  font-weight: bold;
  display: block;
  margin-top: 30px;
}

@media screen and (max-width: 1500px) {
  .block3__title {
    font-size: 54px;
    line-height: 78px;
  }
  .block3__list__img {
    width: 150px;
    height: 150px;
  }
  .block3__list__title {
    font-size: 26px;
    line-height: 46px;
  }
  .block3__list:hover .block3__list__title {
    bottom: 190px;
  }
  .block3__list__text {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .block3__title {
    font-size: 44px;
    line-height: 64px;
    border-bottom: 7px solid #31373E;
  }
  .block3__list__img {
    width: 115px;
    height: 115px;
  }
  .block3__list__title {
    line-height: 40px;
  }
  .block3__list:hover .block3__list__title {
    bottom: 145px;
  }
  .block3__list__text {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1000px) {
  .block3__title__container {
    margin-right: 40px;
  }
  .block3__title {
    font-size: 34px;
    line-height: 36px;
    border-bottom: none;
  }
  .block3__title__border {
    height: 4px;
    width: 200px;
    background: #31373E;
    display: block;
    float: right;
    margin-top: 15px;
  }
  .block3__list__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px auto;
  }
  .block3__list__img {
    width: 183px;
    height: 186px;
  }
  .block3__list__title {
    font-size: 24px;
    line-height: 33px;
  }
  .ammoniumSulfate{
    margin-top: 0;
  }
  .sulfur {
    margin-top: 0;
  }
  .block3__list {
    margin-top: 100px;
    width: 80%;
  }
   .block3__list:first-child {
    margin-top: 0;
  }
  .block3__list:hover .block3__list__title {
    bottom: 220px;
  }
  .block3__list__text {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 750px) {
  .block3__list__container {
    margin: 90px auto;
  }
  .block3__title {
    font-size: 24px;
    line-height: 33px;
  }
  .block3__list__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 600px) {
  .block3__list__container {
    margin: 90px auto;
  }
  .block3__title__container {
    margin-right: 30px;
  }
  .block3__list {
    margin-top: 60px;
  }
  .block3__title {
    text-align: right;
    margin-left: auto;
    width:100%;
  }
  .block3__title__border {
    width: 140px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .block3__list__text {
    font-size: 14px;
    line-height: 20px;
  }
}